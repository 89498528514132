
import { Chip, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import './TaskList.css'

const TasksList = (props) => {


const handleClickChip = (task) => {
    props.handleClickTask(task);
}

  return (
    <div className='taskList'>
      <Stack spacing={1}>
          {props.tasks.map((task) => (
            <Tooltip key={task.taskId} title={task.name} placement='left-start'>
              <Chip 
              style={{fontSize:12}}
                  key={task.taskId}
                  size="small"
                  label={task.code === null ? task.name : task.code}
                  onClick={() => handleClickChip(task)}
                  variant='outlined'
                  disabled={props.disabled}
                  sx={{cursor: 'pointer'}}
                  />
            </Tooltip>
          ))}
      </Stack>
    </div>
  );
};

export default TasksList;