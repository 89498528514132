import {
    FETCH_ERROR,
    FETCH_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,

    FETCH_USER_CURRENT_TASK_START,
    CURRENT_TASK_DATA,
    CURRENT_TASK_CURRENT_TIME,
    CURRENT_TASK_RUNNING_TIME,
    FETCH_USER_AFFECTED_TASKS_START,
    USER_AFFECTED_TASKS_DATA,

    END_TASK_START,
    END_TASK_SUCCESS,

    ADD_COMMON_TASK_START,
    ADD_COMMON_TASK_SUCCESS,

    RESET_CURRENT_TASK_START,
    RESET_CURRENT_TASK_SUCCESS,

    FETCH_TASKS_ORDER_START,
    TASKS_ORDER_DATA,

    FETCH_MANAGER_TASKS_START,
    MANAGER_TASKS_DATA,

    SWAP_TASK_START,
    SWAP_TASK_SUCCESS,

    SET_OFFICER_PREFERED_TASKS_FOR_UNIT

} from "../../constants/ActionsTypes";
import axios from 'util/Api';

export const fetchCurrentTask = (token) => {
    return (dispatch) => {
        dispatch({ type: FETCH_USER_CURRENT_TASK_START });
        axios.get('/getcurrenttask',{
            headers: {
              'Authorization': `Bearer ${token}`
            }
          })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: CURRENT_TASK_DATA, payload: data.result.tasks });
                    dispatch({ type: CURRENT_TASK_CURRENT_TIME, payload: data.result.currenttime });
                    dispatch({ type: CURRENT_TASK_RUNNING_TIME, payload: data.result.runningtime });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchTasks = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_USER_AFFECTED_TASKS_START });
        axios.get('/getaffectedtasksforuser',{
            params: {
                unitId: unitId
            }
          })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: USER_AFFECTED_TASKS_DATA, payload: data.result.tasks });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const endTask = (task) => {
    return (dispatch) => {
        dispatch({ type: END_TASK_START });
        axios.post('/endtask', {
            ...task
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: END_TASK_SUCCESS});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const addCommonTask = (commonTaskValue) => {
    return (dispatch) => {
        dispatch({ type: ADD_COMMON_TASK_START });
        axios.post('/addcommontask', {
            ...commonTaskValue
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: ADD_COMMON_TASK_SUCCESS});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const resetCurrentTask = () => {
    return (dispatch) => {
        dispatch({ type: RESET_CURRENT_TASK_START });
        axios.post('/resetcurrenttask', {

        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: RESET_CURRENT_TASK_SUCCESS});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchTasksOrder = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_TASKS_ORDER_START });
        axios.get('/getofficerpreferedtasksforunit',{
            params: {
                unitId: unitId
            }
          })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: TASKS_ORDER_DATA, payload: data.result.preferedOrder });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const fetchManagerTasks = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_MANAGER_TASKS_START });
        axios.get('/getofficertasksforunit',{
            params: {
                unitId: unitId
            }
          })
            .then(({ data }) => {
                console.log(data)
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: MANAGER_TASKS_DATA, payload: data.result.officertasks });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const swapTask = () => {
    return (dispatch) => {
        dispatch({ type: SWAP_TASK_START });
        axios.post('/swaptask', {

        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: SWAP_TASK_SUCCESS});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};


export const setofficerpreferedtasksforunit = (unitId, taskOrder) => {
    return (dispatch) => {
        axios.post('/setofficerpreferedtasksforunit', {
            unitId: unitId,
            officerPreferedTasksOrderForUnit : taskOrder
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: SET_OFFICER_PREFERED_TASKS_FOR_UNIT});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};