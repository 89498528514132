
import { Stack } from '@mui/material';
import React from 'react';
import './PastTasksBox.css';
const PastTasksBox = (props) => {


const computeTime = (sec) => {
    let hours = (Math.floor((sec / (60 * 60)) % 24));
    let minutes = (Math.floor((sec / 60) % 60));
    let seconds = (Math.floor(sec % 60));
    let stringTime = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0')
    return stringTime

}



  return (
    <div className='pastTaskContainer'>
      <Stack spacing={1}>
          {props.pastTasks.map((task,index) => (
            <div key={index} className='pastTask' style={{ color: task.color }} >
                <div>{task.taskname}</div>
                <div>Realized : {computeTime(task.tasktime)}</div>
                <div>Reference : {computeTime(task.taskreference)}</div>
              </div>
          ))}
      </Stack>
    </div>
  );
};

export default PastTasksBox;