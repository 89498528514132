import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Navigate,  } from "react-router-dom";
import { useSelector } from 'react-redux';
import Home from "./routes/Pages/Home";
import HomeManager from "./routes/Pages/HomeManager";
import Error404 from "./routes/Pages/404";
import SignIn from "./routes/Pages/SignIn";
import {IntlProvider} from "react-intl";
import AppLocale from "lngProvider";

const ProtectedRoute = ({ children }) => {
  const {token} = useSelector(({auth}) => auth);
  if (!token) {
    // user is not authenticated
    return <Navigate to="/SignIn" />;
  }
  return children;
};

function App() {

  const currentAppLocale = AppLocale['fr'];
  
  const { authUser } = useSelector(({ auth }) => auth);
  
    return (      
      <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
          <Router>
            <Routes>              
              <Route path="/SignIn" element={<SignIn />} />
              {authUser != null && authUser.isManager ? 
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <HomeManager />
                  </ProtectedRoute>
                }
              /> :
                <Route
                  path="/"
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />}
              <Route element={Error404} />
              
            </Routes>
          </Router>
      </IntlProvider>
    );
  }

export default App;
