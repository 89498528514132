import axios from 'axios';

//const baseURL = process.env.NODE_ENV === 'production' ? `https://andonline.lean4-0.be/api/v2.0/` : `http://127.0.0.1:5000/api/v2.0/`
//const baseURL = `https://andonline.lean4-0.be/api/v2.0/`
const baseURL = process.env.REACT_APP_API_URL;
//const baseURL = "http://localhost:5000/api/v2.0/";
export default axios.create({
  baseURL: baseURL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
});

const webAppUrl = process.env.REACT_APP_WEB_APP_URL;
//const webAppUrl = "http://localhost:3200";
export const appURL = webAppUrl;

//export const EXTIME_URL = "https://andonline.lean4-0.be/";
export const EXTIME_URL = process.env.REACT_APP_EXTIME_URL;

