import {
    FETCH_UNIT_COACHINGS_START,
    COACHINGS_DATA
  } from "../../constants/ActionsTypes";
  
  const INIT_STATE = {
    coachings: [],
    coachingsLoaded: false,
  };
  
  const Coachings = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case FETCH_UNIT_COACHINGS_START: {
        return {
          ...state,
          coachings: [],
          coachingsLoaded: false,
        };        
      }
  
      case COACHINGS_DATA: {
        return {
          ...state,
          coachings: action.payload === undefined ? [] : action.payload,
          coachingsLoaded: true
        };
      }
  
      default:
        return state;
    }
  }
  
  export default Coachings