import {combineReducers} from 'redux';
import Auth from './Auth';
import Units from './Units';
import Tasks from './Tasks';
import Issues from './Issues';
import Coachings from './Coachings';

export default () => combineReducers({
  auth: Auth,
  units: Units,
  tasks: Tasks,
  issues: Issues,
  coachings: Coachings,
});
