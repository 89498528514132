import { Chip, CircularProgress, MenuItem, Paper, Select, Stack, TextField, Button } from '@mui/material';
import React from 'react';
import mqtt from "mqtt";
import { useEffect, useState } from 'react';
import axios from 'util/Api';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Tooltip from '@mui/material/Tooltip';


import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import IconButton from '@mui/material/IconButton';



import { fetchUserUnits } from 'redux/actions/Units';
import { fetchManagerTasks } from 'redux/actions/Tasks';
import { userSignOut } from 'redux/actions/Auth';

import { LOGOFF_COMMON_TASK_VALUE } from 'constants/ConstantTypesValues';
import ManagerTasksList from './ManagerTaskList';


const HomeManager = () => {

   


     const dispatch = useDispatch();

     const token = useSelector(({ auth }) => auth.token);
//     const { userUnitsLoaded, userUnits } = useSelector(({ units }) => units);
//     const { managerTasksLoaded, managerTasks} = useSelector(({ tasks }) => tasks)

//     const [client, setClient] = useState(null); // Store the MQTT client

//     const [unit, setUnit] = useState(userUnits.length > 0 ? userUnits[0] : null);

//     const mqttConnect = (host, mqttOption) => {
//       setConnectStatus('Connecting');
//       setClient(mqtt.connect(host, mqttOption));
//     };
    

    

//     const handleChangeUnit = (event) => {
//         const selectedUnit = userUnits.find(unit => unit.unitId === event.target.value);
//         setUnit(selectedUnit);
//         dispatch(fetchManagerTasks(event.target.value))
//     };



    const handleLogOffButtonClick = () => {
        dispatch(userSignOut());
    }

//     useEffect(() => {
//       if (client) {
//         console.log(client)
//         client.on('connect', () => {
//           setConnectStatus('Connected');
//         });
//         client.on('error', (err) => {
//           console.error('Connection error: ', err);
//           client.end();
//         });
//         client.on('reconnect', () => {
//           setConnectStatus('Reconnecting');
//         });
//         client.on('message', (topic, message) => {
//           const payload = { topic, message: message.toString() };
//           setPayload(payload);
//         });
//       }
//     }, [client]);
  


//     useEffect(() => {
       
//         if (token) {
//           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//         }
    
//         if (!userUnitsLoaded) {
//           dispatch(fetchUserUnits(token));
//         } else {
//           if (userUnits.length > 0 && !unit) {
//             setUnit(userUnits[0]);
//           }
//         }
    
//         if (unit && !managerTasksLoaded) {
//           dispatch(fetchManagerTasks(unit.unitId));
//         }
    
       
//       }, [dispatch, userUnitsLoaded, managerTasksLoaded, unit, token, userUnits]);
    

     return (
        <div>
            <div>En cours développement</div>
            <div>
                 <Tooltip title="Log off">
                     <IconButton onClick={handleLogOffButtonClick}>
                         <LogoutIcon/>
                     </IconButton>
                 </Tooltip>
             </div>
        </div>
        
//     <div className='container'>
//         <Paper className='paper' elevation={1}>

//             <TextField
//                 value={unit ? unit.unitId : ''}
//                 onChange={handleChangeUnit}
//                 select // tell TextField to render select
//                 label="Cellule"
//                 size="small"
//                 >
//                 {userUnits.map((unit) => (
//                         <MenuItem key={unit.unitId} value={unit.unitId}>{unit.name}</MenuItem>
//                     ))}
//             </TextField>

//             <div style={{ marginBottom: '16px' }}>
//                     {managerTasksLoaded ? <ManagerTasksList managerTasks={managerTasks} /> : <CircularProgress />}
//             </div>
            
//             <div>
//                 <Tooltip title="Log off">
//                     <IconButton onClick={handleLogOffButtonClick}>
//                         <LogoutIcon/>
//                     </IconButton>
//                 </Tooltip>
//             </div>
//             <div>
//                 <a href={URL_EXTIME_WEB + "authenticate?token=" + token} target='_blank'>
//                     <Tooltip title="Accéder à l'application Web">
//                         <IconButton>
//                             <DashboardIcon/>
//                         </IconButton>
//                     </Tooltip>
//                 </a>
//             </div>

//         </Paper>
//         </div>
        
     );
 };


export default HomeManager;