import {
    FETCH_ERROR,
    FETCH_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    ERROR_OTHERS,

    FETCH_UNIT_COACHINGS_START,
    COACHINGS_DATA,

    ADD_COMMON_TASK_COACHING_START,
    ADD_COMMON_TASK_COACHING_SUCCESS



} from "../../constants/ActionsTypes";
import axios from 'util/Api';

export const fetchCoachings = (unitId) => {
    return (dispatch) => {
        dispatch({ type: FETCH_UNIT_COACHINGS_START });
        axios.get('/coachings',{
            params: {
                unitId: unitId
            }
          })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: FETCH_SUCCESS });
                    dispatch({ type: COACHINGS_DATA, payload: data.result.coachings });
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({ type: SIGNOUT_USER_SUCCESS });
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};

export const addcommontaskCoaching = (coachings) => {
    return (dispatch) => {
        dispatch({ type: ADD_COMMON_TASK_COACHING_START });
        axios.post('/addcommontaskCoaching', {
            ...coachings
        })
            .then(({ data }) => {
                if (data.result) {
                    dispatch({ type: ADD_COMMON_TASK_COACHING_SUCCESS});
                } else {
                    console.log("payload: data.error", data.error);
                    dispatch({ type: FETCH_ERROR, payload: "Network Error" });
                }
            }).catch(function (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    dispatch({type: SIGNOUT_USER_SUCCESS});
                } else {
                    dispatch({ type: ERROR_OTHERS, payload: error });
                }
                dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            });
    }
};


